<template>
  <div id="app" class="wrapper">
<!--    <img alt="Vue logo" src="./assets/logo.png">-->
    <template v-if="isAuthenticated === true">
      <div v-if=" authUser.user_permissions ">
        <TopNav />
        
       

        <SideNav />
        <div class="content-wrapper">
        

        <div class="content-header">
          <div class="container-fluid">
            <router-view/>
            <page-loading :active.sync="pageLoading" :is-full-page="false" :background-color="'#40B482'"
              :opacity="0.2"
            ></page-loading>
          </div>
        </div>
      </div>
        

      </div>
      <div v-else>
        
        
      

      <TopNavEmp />
      <SideNavEmpNew />
      authUser.id


      <div class="content-wrapper">
        

        <div class="content-header">
          <div class="container-fluid">
            <router-view/>
            <page-loading :active.sync="pageLoading" :is-full-page="false" :background-color="'#40B482'"
              :opacity="0.2"
            ></page-loading>
          </div>
        </div>
      </div>
    </div>
    </template>

    <template v-else>
      <template v-if="isResetPasswordRoute" >
        <reset-password></reset-password>
      </template>
      <template v-else>
        <!-- <HomePage></HomePage> -->
        <LogIn></LogIn>
      </template>
     
    </template>
  </div>
</template>
<script>
const SideNav = () => import('@/components/SideNav')
const SideNavEmpNew = () => import('@/components/SideNavEmpNew')
const TopNav = () => import('@/components/TopNav')
const TopNavEmp = () => import('@/components/TopNavEmp')
// const HomePage = () => import('@/views/home/LandingPage')
const LogIn = () => import('@/views/auth/Login')
const ResetPassword = () => import('@/views/auth/ResetPassword')
//logn upddated
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  components: {
    SideNav,
    SideNavEmpNew,
    TopNav,
    TopNavEmp,
    // HomePage,
    LogIn,
    ResetPassword
  },
  computed: {
    ...mapGetters('Auth',['isAuthenticated','authUser', 'authError', 'authStatus']),
    ...mapGetters('Loading',['pageLoading',]),
    isResetPasswordRoute() {
      return this.$route.name === 'ResetPassword';
    },
  },
  watch: {
    isAuthenticated() {
      if (this.isAuthenticated === true) {
        window.toast.fire({
          icon: 'success',
          title: 'Login successful'
        })
      } else {
        window.toast.fire({
          icon: 'success',
          title: 'Logged out'
        })
      }

    },
    '$i18n.locale': function(newVal) {
      localStorage.setItem('last-locale', newVal)
    }
  },
  mounted(){
  
      if (this.isAuthenticated !== true) {
        this.$router.push('/login');
        console.log("wohoooooow")
      } 
     
    
  }

}
</script>

<style>

</style>
<!-- function sessionExpired(expirationTimestamp) {
  // Convert the expiration timestamp to a moment object
  const expirationMoment = moment.unix(expirationTimestamp);

  // Get the current moment
  const currentMoment = moment();

  // Compare the expiration moment with the current moment
  return expirationMoment.isBefore(currentMoment);
} -->
<!-- router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.Auth.authUser !== null;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiredRoles = to.meta.roles;

  if (sessionExpired(store.state.Auth.authUser.exp)) {
    // Session has expired, destroy authUser from Vuex state and local storage, perform logout actions
    // store.commit('Auth/destroyAuthUser'); // Assuming you have a mutation to destroy authUser
    localStorage.removeItem('vuex'); // Adjust the key based on your Vuex store configuration

    console.log("Token has expired");
    
    // Redirect to login page
    next({
      name: 'Login',
      query: {
        returnUrl: to.fullPath,
      },
    });
  } else if (requiresAuth) {
    if (!isAuthenticated) {
      // Redirect to login page if authentication is required but the user is not logged in
      next({ name: 'Login', query: { returnUrl: to.fullPath } });
    } else if (requiredRoles && !checkRoles(requiredRoles, store.state.Auth.authUser.roles)) {
      // Redirect or handle unauthorized access
      next({ name: 'Unauthorized' });
    } else {
      // Continue to the requested route
      next();
    }
  } else {
    // Continue to the requested route for routes that don't require authentication
    next();
  }
}); -->