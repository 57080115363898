// import user from '@/services/user';
// import Utils from '../../utils/Cookies';

const initialState = () => ({
    loading: false,
});

const state = initialState();

const getters = {
    pageLoading (state) {
        return state.loading
    },
}

const mutations = {
    updateLoading (state, loading) {
        state.loading = loading
    },
}

const actions = {
    updateLoading ({ commit }, payload) {
        commit('updateLoading', payload)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
