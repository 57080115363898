import auth from '../../services/authentication/auth';
import Utils from '../../utils/Cookies';

const initialState = () => ({
    authStatus: 0, // 1 - Loading, 2 - Successful, 3 - unsuccesful, 4 - awaiting verification
    authMessage: null,
    token: (Utils.authTokenExists() && Utils.getToken()) || '',
    authUser: null, //(Utils.authTokenExists()) || [],
    authError: null,

});

const state = initialState();

const getters = {
    authStatus: state => state.authStatus,
    authMessage: state => state.authMessage,
    isAuthenticated(state) {
        if (state.authStatus !== 2) {
            return false
        } else {
            return true
        }
    },
    authUser(state) {
        return state.authUser
    },
    authError(state) {
        return state.authError
    },

}

const mutations = {
    authRequest(state) {
        state.authStatus = 1
    },
    authVerified(state) {
        state.authStatus = 2
    },
    authSuccess(state, response) {
        state.authStatus = 2
        state.authMessage = 'Logged in successfully'
        state.token = response.token.access
        state.authUser = response.decodedToken
        // do domething with the response
    },
    authVerify(state, response) {
        state.authStatus = 4
        state.authMessage = 'Verify'
        state.token = response.token.access
        state.authUser = response.decodedToken
        // do domething with the response
    },
    authVerifyError(state, error) {
        state.authStatus = 4
        state.authError = error
    },

    authError(state, error) {
        state.authStatus = 3
        state.authError = error
    },
    authLogout(state){
        state.authStatus = 0
        state.authMessage = null
        state.isAuthenticated = false
        state.token = ''
        state.authUser = []
    },
    updateToken(state, token) {
        state.token = token
    },

    // Reset
    reset(state) {
        const newState = initialState()
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    }
}

const actions = {
    login: async ({ commit }, payload) => {
        try {
            commit('authRequest')
            const response = await auth.login(payload);

            let userData = Utils.saveToken(response.data);
            console.log(userData)
            commit('authSuccess', userData);

            // if (userData.decodedToken.two_fa === 'yes') {
            //     commit('authVerify', userData);
            // } else {
            //     commit('authSuccess', userData);
            // }
        } catch (err) {
            console.log(err)
            if (err.response) {
                let error = ""
                if (err.response.data.error) {
                    error = err.response.data.error
                } else {
                    error = err.response.data.detail
                }
                commit('authError', error)
            }
        }
    },
    verify_tfa: async ({ commit }, payload) => {
        try {
            commit('authVerifyError', '')
            await auth.verifyTwoFA(payload);
            commit('authVerified')
        } catch (err) {
            if (err.response) {
                let error = ""
                if (err.response.data.error) {
                    error = err.response.data.error
                }
                commit('authVerifyError', error)
            }
        }
    },
    setToken({ commit }, payload) {
        let userData = Utils.setToken(payload);
        commit('authSuccess', userData);
        commit('updateToken', payload);
    },
    logout({ commit }){
        Utils.removeToken('userData')
        Utils.removeToken('token')
        commit('reset')
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}